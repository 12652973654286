import * as actionTypes from './friendActionTypes'
import api from '../../axios/api';
import {setNewNotification} from '../notifications/notificationActions'

const sendFriendRequest = () => {
  return {
    type: actionTypes.SEND_FRIEND_REQUEST,
  }
}

const sendFriendRequestSuccess = () => {
  return {
    type: actionTypes.SEND_FRIEND_REQUEST_SUCCESS,
  }
}

const sendFriendRequestFail = (error) => {
    return {
        type: actionTypes.SEND_FRIEND_REQUEST_FAIL,
        payload: error,
    }
}

export const friendRequest = (credentials) => {
    return  async (dispatch) => {
      dispatch(sendFriendRequest);
      return api.post('/friendship', credentials)
        .then(response => {
          // localStorage.setItem('access_token', user.token)
          dispatch(sendFriendRequestSuccess(response.data));
          return response
        })
        .catch(e =>{
          console.log(e);
          dispatch(sendFriendRequestFail(e));
          return e
        })
    }
}

const getFriendListRequest = () => {
  return {
    type: actionTypes.GET_FRIEND_LIST_REQUEST,
  }
}

const getFriendListSuccess = (friendsList, requestedOnlyIds=false) => {
  return {
      type: actionTypes.GET_FRIEND_LIST_SUCCESS,
      payload: {
        friendsList: friendsList,
        requestedOnlyIds: requestedOnlyIds
      }
  }
}

const getFriendListFail = (error) => {
  return {
      type: actionTypes.GET_FRIEND_LIST_FAIL,
      payload: error,
  }
}

export const getFriendList = (requestedOnlyIds=false) => {
  return async (dispatch) => {
    dispatch(getFriendListRequest())
    api.get('/friendship', {params: {requestedOnlyIds: requestedOnlyIds}})
      .then(response => {
        dispatch(getFriendListSuccess(response.data, requestedOnlyIds));
        })
        .catch(e =>{
          console.log(e);
          dispatch(getFriendListFail(e));
      })
  }
}


const sendAcceptRequest = () => {
  return {
      type: actionTypes.SEND_ACCEPT_FRIEND_REQUEST,
  }
}

export const sendAcceptSuccess = (friend) => {
  return {
      type: actionTypes.SEND_ACCEPT_FRIEND_REQUEST_SUCCESS,
      payload: {
        friend: friend
      }
    }
}

const sendAcceptFail = () => {
  return {
      type: actionTypes.SEND_ACCEPT_FRIEND_REQUEST_FAIL,
  }
}

export const sendAccept = (friendshipId, notificationUuid) => {
  return async (dispatch) => {
    dispatch(sendAcceptRequest())
    // debugger;
    return api.post(`/friendship/${friendshipId}/accept`, {
      'notificationUuid': notificationUuid
    }, {
      headers: {hideErrorPage: true}
    })
    .then(response => {
      dispatch(sendAcceptSuccess(response.data));
      dispatch(setNewNotification({
        ...response.data,
        notificationId: notificationUuid || response.data.notificationUuid,
        type: 'friendAccepted'
      }))

      const friendId = response?.data?.id;
      if (friendId) {
        dispatch(updateFriendOnlineStatus({friendId: friendId, onlineStatus: true}));
      }

      return response.data
    })
    .catch(e =>{
      console.log(e);
      dispatch(setNewNotification({
        // ...response.data,
        notificationId: notificationUuid || response.data.notificationUuid,
        type: 'friendAccepted'
      }))
      dispatch(sendAcceptFail());
      return e
    })
  }
}

const sendDeclineRequest = () => {
  return {
    type: actionTypes.SEND_DECLINE_FRIEND_REQUEST,
  }
}

const sendDeclineSuccess = (friendsList) => {
  return {
      type: actionTypes.SEND_DECLINE_FRIEND_REQUEST_SUCCESS,
      payload: {
        friendsList: friendsList
      }
  }
}

const sendDeclineFail = () => {
  return {
      type: actionTypes.SEND_DECLINE_FRIEND_REQUEST_FAIL,
  }
}

export const sendDecline = (friendshipId, notificationUuid) => {
  return async (dispatch) => {
    dispatch(sendDeclineRequest())
    api.post(`/friendship/${friendshipId}/decline`, {
        'notificationUuid': notificationUuid
    }, {
      headers: {hideErrorPage: true}
    })
      .then(response => {
          dispatch(sendDeclineSuccess(response.data));
          dispatch(setNewNotification({
            ...response.data,
            notificationId: notificationUuid,
            type: 'friendDeclined'
          }))
        })
        .catch(e =>{
          console.log(e);
          dispatch(setNewNotification({
            // ...response.data,
            notificationId: notificationUuid,
            type: 'friendDeclined'
          }))
          dispatch(sendDeclineFail);
      })
  }
}



const sendDeleteRequest = () => {
  return {
      type: actionTypes.SEND_DELETE_FRIEND_REQUEST,
  }
}

export const sendDeleteRequestSuccess = (friendshipId) => {
  return {
      type: actionTypes.SEND_DELETE_FRIEND_REQUEST_SUCCESS,
      payload: {
        friendshipId
      }
  }
}

const sendDeleteRequestFail = () => {
  return {
      type: actionTypes.SEND_DELETE_FRIEND_REQUEST_FAIL,
  }
}

export const sendDelete = (friendshipId) => {
  return async (dispatch) => {
    dispatch(sendDeleteRequest())
    return api.delete(`/friendship/${friendshipId}`)
      .then(response => {
          dispatch(sendDeleteRequestSuccess(friendshipId));
          return true
        })
        .catch(e =>{
          console.log(e);
          dispatch(sendDeleteRequestFail());
          return false
      })
  }
}


const sendBlockRequest = () => {
  return {
      type: actionTypes.SEND_DELETE_FRIEND_REQUEST,
  }
}

const sendBlockRequestSuccess = (friendsList) => {
  return {
      type: actionTypes.SEND_DELETE_FRIEND_REQUEST_SUCCESS,
      payload: {
        friendsList: friendsList
      }
  }
}

const sendBlockRequestFail = () => {
  return {
      type: actionTypes.SEND_DELETE_FRIEND_REQUEST_FAIL,
  }
}

export const sendBlock = (friendshipId) => {
  return async (dispatch) => {
    dispatch(sendBlockRequest())
    api.post(`/friendship/${friendshipId}/block`)
      .then(response => {
          dispatch(sendBlockRequestSuccess(response.data));
        })
        .catch(e =>{
          console.log(e);
          dispatch(sendBlockRequestFail);
      })
  }
}


const searchPeopleRequest = () => {
  return {
      type: actionTypes.SEARCH_PEOPLE_REQUEST,
  }
}

export const searchPeopleSuccess = (peopleList) => {
  return {
      type: actionTypes.SEARCH_PEOPLE_REQUEST_SUCCESS,
      payload: {
        peopleList: peopleList
      }
  }
}

const searchPeopleFail = () => {
  return {
      type: actionTypes.SEARCH_PEOPLE_REQUEST_FAIL,
  }
}

export const searchPeople = (payload) => {
  return async (dispatch) => {
    dispatch(searchPeopleRequest())
    api.get(`/friendship/search?keyword=${payload.keyword}`)
      .then(response => {
          dispatch(searchPeopleSuccess(response.data));
        })
        .catch(e =>{
          console.log(e);
          dispatch(searchPeopleFail(e));
      })
  }
}

export const setReferralEmail = (email) => {
  return {
    type: actionTypes.SET_REFFERAL_EMAIL,
    payload: email
  }
}

export const setReferralAvatar = (avatar) => {
  return {
    type: actionTypes.SET_REFFERAL_AVATAR,
    payload: avatar
  }
}

export const updateFriendOnlineStatus = (payload) => {
  // debugger;
  return {
    type: actionTypes.UPDATE_FRIEND_ONLINE_STATUS,
    payload
  }
}

export const clearFriendsList = () => {
  return (dispatch) => {
    dispatch(getFriendListSuccess([]));
  }
}
